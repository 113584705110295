<template>
  <div class="index">
    <div class="banner" >
      <div>{{$t('lang.h52')}}</div>
      <div>{{$t('lang.h53')}}</div>
      <div class="img" :style="`background: url(${ColorCheckData=='black'? bglist.image: bglist.image1}) no-repeat;`">
       <div class="img_abs">{{$t('lang.pro15')}}</div>
      </div>
     
    </div>
    <div class="functional_region">
      <div class="region">
        <div v-for="(item,index) of functionOption"
             :key="index"
             @click="switchovers(index)"
             :class="index ==regionIndex?'special':''">
          <span>{{$t(item.name)}}</span>
        </div>
      </div>
    </div>

    <div class="box">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { basic } from '../../api/index';
export default {
  data () {
    return {
      // 功能列表
      functionOption: [{ name: 'lang.swap77' }, { name: 'lang.swap78' },{ name: 'V1' }],
      regionIndex: '0',
      bglist:{
        image:require('@/assets/img/swap_banner.png'),
        image1:require('@/assets/img/swap_banner1.png'),
      },
    };
  },
  computed: {
    ...mapState(['account', 'lang','ColorCheckData']),
  },
  created () {
    // this.getBasicList();
    if (this.$route.query.id) {
      this.regionIndex = this.$route.query.id;
      console.log(this.regionIndex);
    }
  },
  mounted () {

  },
  methods: {
    switchovers (index) {
      this.regionIndex = index;
      switch (this.regionIndex) {
      case 0:{
        this.$router.push({ name: 'SwapV2' });
        break;
      };
     
      case 1:{
        this.$router.push({ name: 'LiquidityV2Index' });
        break;
      };
      case 2:{
        this.$router.push({ name: 'Swap' });
        break;
      };
      default:
        break;
    }
    },

    getBasicList () {

      let data = {
        page: 1,
        page_size: 20,
      };
      basic.getNoticeList(data, '0x3D12Bd39bB936a73575ea97dFbf308b08b84e76B', "zh-cn").then(res => {
        if (res.code === 200) {
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  display: flex;
  flex-direction: column;
  background: var(--bg-color);
  min-height: 100vh;
  width: 100%;
 .banner{
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  &>div{
    color: var( --text-color);
    font-weight: bold;
  }
  &>div:nth-child(1){
    font-size: 24px;
    margin-bottom: 5px;
  }
  &>div:nth-child(2){
    font-size: 18px;
    margin-bottom: 19px;
  }
  .img{
    position: relative;
    width: 100%;
    // background: url('~@/assets/img/swap_banner.png') no-repeat;
    background-size: contain !important;
    height: 98px;
    .img_abs{
      left: 18px;
      bottom: 16px;
      position: absolute;
   
background: var( --box-bg-color);
font-size: 20px;
color: rgba(152, 232, 110, 1);
    }
  }
 }
}
.box {
  border-radius: 30px 30px 0 0;
  background: var( --box-bg-color);
}
.functional_region {

  padding: 20px 20px 0;
 padding-top: 0;
  .region {
    display: flex;
    width: 100%;
    line-height: 22px;
    font-size: 16px;
    border-radius: 30px;
 padding: 3px;
border: 1px solid rgba(52, 247, 244, 0.49);
    font-family: PingFangSC-Semibold, PingFang SC;
    color: rgba(158,156,163, 1);
    font-weight: 600;
    // background:  rgba(69, 131, 255, 1);
    border-radius: 34px;
    // margin: 20px 0;
    margin-bottom: 20px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      text-align: center;
      // width: 192rpx;
      padding: 16px 0;
    }
    .special {
      // width: 50%;
      color: #000;
      background: rgba(152, 232, 110, 1);
      // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
      border-radius: 34px;
    }
  }
}
/deep/ .router {
  // background: #000 !important;
}
</style>