<template>
    <div class="engbill">
      <div class="recruit_title">
        <div style="transform: rotate(90deg); color: #fff" @click="toprouter"><van-icon name="down" /></div>
        <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
        <div></div>
      </div>
     
      <div class="functional_region" id="myElementId">
        <div class="region">
          <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)"
            :class="index == regionIndex ? 'special' : ''" style="margin-right: 26px;">
            <span>{{ $t(item.name) }}</span>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="li first">
          <div>{{ $t('lang.s136') }}</div>
          <div>{{ $t('lang.s137') }}</div>
          <div>{{ $t('lang.s138') }}</div>
        </div>
        <van-list v-model="recordlist.loading" :finished="recordlist.finished" :finished-text="`${$t('lang.d145')}`"
        @load="onLoadrecord">
        <div class="li" v-for="(item, index) in recordlist.list" :key="index">
          <div style="color: rgba(153, 153, 153, 1);">{{ item.created_at }}</div>
          <div style="color: rgba(56, 248, 252, 1);">+{{ item.usable_change }}</div>
          <div style="color: rgba(153, 153, 153, 1);">{{ item.token }}</div>
        </div>
        </van-list>
      
      </div>
    </div>
  </template>
  
  <script>
  import { nft } from '../../api/index';
  import { mapState } from 'vuex';
  import Web3 from 'web3';
  export default {
    data() {
      return {
        functionOption: [{ name: '铸币收益' }, { name: '交易收益' }],
        regionIndex: '0',
        loading:true,
        recordlist: {
          loading: false,
          finished: false,
          list: [],
          info: {
            page: 1,
            pageSize: 10,
            type: '0'
          }
  
        }
      }
    },
    components: {
  
    },
    computed: {
      ...mapState(['account', 'lang']),
    },
    watch: {
      account() {
  
        this.init()
      },
      lang() {
        this.init()
      }
    },
    created() {
    //   this.init()
    },
    mounted() {
  
    },
    methods: {
      switchovers(index) {
        this.regionIndex = index;
    
        this.recordlist ={
          loading: false,
          finished: false,
          list: [],
          info: {
            page: 1,
            pageSize: 10,
            type: '0'
          }
  
        }
        this.recordlist.info.type = String(index);
        //  this.nftwalletrecord()
  
      },
      toprouter() {
        this.$router.go(-1);
  
      },
      init() {
        
        this.loading = true
        this.regionIndex = '0'
        this.recordlist ={
          loading: false,
          finished: false,
          list: [],
          info: {
            page: 1,
            pageSize: 10,
            type: '0'
          }
  
        }
        this.nftwalletrecord()
      },
      onLoadrecord(){
        //    this.nftwalletrecord()
      },
      nftwalletrecord() {
  
        nft.nftwalletrecord(this.recordlist.info, this.account).then(res => {
          if (res.data.list != null || res.data.list.length > 0) {
            if (this.recordlist.info.page == 1) {
              this.recordlist.list = res.data.list
            } else {
              this.recordlist.list = this.recordlist.list.concat(res.data.list)
            }
            if (res.data.list.length < this.recordlist.info.pageSize) {
              this.recordlist.loading = true
              this.recordlist.finished = true
            } else {
              this.recordlist.info.page++
            }
  
          }
  
        })
      },
  
    },
  }
  
  </script>
  <style scoped lang='less'>
  .functional_region {
  
    .region {
      display: flex;
  
      align-items: center;
      .special {
        font-size: 18px;
        color: #fff;
      }
    }
  }
  
  .engbill {
    padding: 20px;
    padding-top: 52px;
    padding-bottom: 44px;
  
  }
  
  
  .recruit_title {
    color: var(rgba(255, 255, 255, 1));
    padding: 20px 0px;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .list {
    margin-top: 29px;
    padding: 15px 20px;
    width: 100%;
    border-radius: 16px;
    background: rgba(27, 31, 46, 1);
  
    border: 1px solid rgba(36, 40, 52, 1);
  
    .first {
      color: rgba(153, 153, 153, 1);
      padding-bottom: 13px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  
    .li {
      margin-bottom: 15px;
      width: 100%;
      display: flex;
      font-size: 14px;
      font-weight: 700;
  
      &>div {
        width: calc(100% / 3);
        text-align: center;
      }
  
      &>div:first-child {
        text-align: left;
      }
  
      &>div:last-child {
        text-align: right;
      }
    }
  }
  </style>