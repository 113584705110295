<template>
  <div class="home_page">
    <div class="snowballmain">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators="false">
      <van-swipe-item v-for="(item, index) in imglist" :key="index">
        <img :src="`${imgurl}${item.image}`" alt="" class="homeimg" />
      </van-swipe-item>
    </van-swipe>
      <!-- <img src="@/assets/img/test_banner.png" alt="" class="homeimg" /> -->
      <div class="overflow" v-show="gongaoshow">
        <div class="o_cont">
          <img src="@/assets/imgsnowball/gongaox.png" alt="" class="gongaox" @click="gongaox" />
          <div class="title">{{ $t('lang.d98') }}</div>
          <div class="line"></div>
          <div class="content">
            <div style="color: var(--text-color); font-size: 16px">{{ notice.title }}</div>
            <div style="color: #888a8e; font-size: 12px">{{ notice.created_time }}</div>
            <div v-html="notice.content" style="color: var(--text-color); font-size: 14px"></div>
          </div>
        </div>
      </div>
      <div class="routerlink">
        <div class="router_li" v-for="(item, index) in routerlist" @click="bNav(item.router, item.i)" :key="index">
          <div class="imgbox">
            <div class="imgbox_item">
              <img :src="ColorCheckData == 'black' ? item.image : item.image1" alt="" />
            </div>
          </div>

          <span>{{ $t(item.name) }}</span>
        </div>
        <router-link class="notice" :to="{ name: 'xiangqing', query: { id: notice.group_id } }">
          <div style="color: #fff; font-weight: bold; padding-right: 10px; margin-right: 10px; border-right: 1px solid rgba(53, 58, 74, 1)">NEWS</div>
          <span style="color: var(--text-color)">{{ notice.title }}</span>
          <router-link :to="{ name: 'gongao' }" class="shoyi" :style="`background: ${ColorCheckData == 'black' ? 'rgba(80, 80, 80, 1)' : 'rgba(80, 80, 80, 1)'};`">
            <div style="transform: rotate(-90deg); color: #fff">
              <van-icon name="down" />
            </div>
          </router-link>
        </router-link>
      </div>
      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">
        <div class="page_title_item">{{ $t('lang.h92') }}</div>
      </div>
      <div class="rwabox">
        <div class="rwabox_top">
          <div class="rwabox_top_item">
            <div class="rwabox_top_item_title">{{ $t('lang.h93') }}</div>
            <div class="rwabox_top_item_num"><span style="font-size: 12px">$</span> {{ RwaInfo.Rwaprice }}</div>
            <div class="rwabox_top_item_button" @click="bNav('Swap')">
              <img src="@/assets/img/jioayi_icon.png" alt="" />
              <span>{{ $t('lang.h94') }}</span>
            </div>
          </div>
          <div class="rwabox_top_item">
            <div class="rwabox_top_item_title">{{ $t('lang.pro5') }}</div>
            <!-- RwaInfo.production -->
            <div class="rwabox_top_item_num">210000</div>
            <div class="rwabox_top_item_button" style="background: rgba(255, 255, 255, 1)" @click="bNav('earn')">
              <img src="@/assets/img/zhuzao_icon.png" alt="" />
              <span>{{ $t('lang.pro4') }}</span>
            </div>
          </div>
        </div>
        <div class="rwabox_line" style="margin-top: 14px; margin-bottom: 10px">
          <div class="rwabox_line_left">RT{{ $t('lang.pro1') }}</div>
          <!-- (RwaInfo.publish_amount / 10000).toFixed(2) -->
          <div class="rwabox_line_right">{{$t('lang.pro2')}}2%</div>
        </div>
        <!-- <div class="rwabox_line" style="margin-top: 14px; margin-bottom: 10px">
          <div class="rwabox_line_left">{{ $t('lang.h97') }}</div>
          <div class="rwabox_line_right">2100{{ $t('lang.h98') }}</div>
        </div>
        <div class="rwabox_line" style="margin-top: 14px; margin-bottom: 10px">
          <div class="rwabox_line_left">{{ $t('lang.h99') }}:</div>
          <div class="rwabox_line_right">1100{{ $t('lang.h98') }}</div>
        </div> -->
      
      </div>
      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">
        <div class="page_title_item">{{ $t('lang.h87') }}</div>
      </div>
      <div class="assets_box">
        <div class="box_title">{{ $t('lang.h130') }}</div>
        <div class="box_list">
          <AssetsIndex :quoteList="quoteList"></AssetsIndex>
        </div>
      </div>
      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">
        <div class="page_title_item">{{ $t('lang.h100') }}</div>
      </div>
      <div class="aboutSwap">
        <img src="@/assets/img/aboutswap.png" class="aboutSwap_img" alt="" />
        <div class="aboutSwap_box aboutSwap_box1">{{ $t('lang.h101') }}</div>
        <div class="aboutSwap_box">{{ $t('lang.h102') }}</div>
      </div>
      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">
        <div class="page_title_item">{{ $t('lang.h103') }}</div>
      </div>
      <div class="Advantage">
        <div class="Advantage_box">
          <img src="@/assets/img/Advantage1.png" alt="" />
          <div class="Advantage_box_title">{{ $t('lang.h104') }}</div>
          <div class="Advantage_box_text">{{ $t('lang.h105') }}</div>
        </div>
        <div class="Advantage_box">
          <img src="@/assets/img/Advantage2.png" alt="" />
          <div class="Advantage_box_title">{{ $t('lang.h106') }}</div>
          <div class="Advantage_box_text">{{ $t('lang.h107') }}</div>
        </div>
        <div class="Advantage_box">
          <img src="@/assets/img/Advantage3.png" alt="" />
          <div class="Advantage_box_title">{{ $t('lang.h108') }}</div>
          <div class="Advantage_box_text">{{ $t('lang.h109') }}</div>
        </div>
        <div class="Advantage_box">
          <img src="@/assets/img/Advantage4.png" alt="" />
          <div class="Advantage_box_title">{{ $t('lang.h110') }}</div>
          <div class="Advantage_box_text">{{ $t('lang.h111') }}</div>
        </div>
      </div>
      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">
        <div class="page_title_item">{{ $t('lang.h112') }}</div>
      </div>
      <div class="routemap2">
        <img src="@/assets/img/routemap2.png" alt="">
         <div class="routemap2_line">
             <div class="routemap2_line_box" style="margin-right: 30px;">
              <div class="routemap2_line_box_title">{{ $t('lang.h113') }}</div>
              <div class="routemap2_line_box_text">{{ $t('lang.h114') }}</div>
             </div>
         </div>
         <div class="routemap2_line">
             <div class="routemap2_line_box">
              <div class="routemap2_line_box_title">{{ $t('lang.h115') }}</div>
              <div class="routemap2_line_box_text">{{ $t('lang.h116') }}</div>
             </div>
         </div>
         <div class="routemap2_line">
             <div class="routemap2_line_box">
              <div class="routemap2_line_box_title">{{ $t('lang.h117') }}</div>
              <div class="routemap2_line_box_text">{{ $t('lang.h118') }}</div>
              <div class="routemap2_line_box_text">{{ $t('lang.h119') }}</div>
             </div>
         </div>
         <div class="routemap2_line">
             <div class="routemap2_line_box">
              <div class="routemap2_line_box_title">{{ $t('lang.h120') }}</div>
              <div class="routemap2_line_box_text">{{ $t('lang.h121') }}</div>
              <div class="routemap2_line_box_text">{{ $t('lang.h122') }}</div>
             </div>
         </div>
         <div class="routemap2_line">
             <div class="routemap2_line_box">
              <div class="routemap2_line_box_title">{{ $t('lang.h123') }}</div>
              <div class="routemap2_line_box_text">{{ $t('lang.h124') }}</div>
              <div class="routemap2_line_box_text">{{ $t('lang.h125') }}</div>
             </div>
         </div>
         <div class="routemap2_line">
             <div class="routemap2_line_box" style="margin-left: 50px;">
              <div class="routemap2_line_box_title">{{ $t('lang.h126') }}</div>
              <div class="routemap2_line_box_text">{{ $t('lang.h127') }}</div>
              <div class="routemap2_line_box_text">{{ $t('lang.h128') }}</div>
             </div>
         </div>
      </div>
    </div>

    <div class="partner_list">
      <div class="partner">
        <div class="page_title">
          <div class="page_title_item">{{ $t('lang.swap164') }}</div>
        </div>
        <div class="partner_img">
          <div class="img" v-for="(item, index) of institutionsListB" :key="index">
            <img :src="ColorCheckData == 'black' ? item.image : item.image1" />
          </div>
        </div>
      </div>
      <div class="partner">
        <div class="page_title">
          <div class="page_title_item">{{ $t('lang.swap165') }}</div>
        </div>
        <div class="partner_img">
          <div class="img" v-for="(item, index) of institutionsListC" :key="index">
            <img :src="ColorCheckData == 'black' ? item.image : item.image1" />
          </div>
        </div>
      </div>        
 
      <div class="Icon_img">
        <img v-for="(item, index) of institutionsListIcon" :key="index" :src="item" class="img" />
      </div>
      <div class="reserved">
        <span>Copyright@2024 Rswap.All rights reserved.</span>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { kuangchi, homedata, user, bizhong, moneymask, gongao, web3_api } from '../../api/index';
import AssetsIndex from '@/components/AssetsIndex.vue';

export default {
  data() {
    return {
      quoteList:[],
      notice: {},
      gongaoshow: false,
      imgurl: '',
      imglist: [],
      checked: false,
      zhicanlist: [],
      routerlist: [
        {
          image: require('@/assets/img/invite_icon.png'),
          image1: require('@/assets/imgsnowball/chobi1.png'),
          name: 'lang.h5',
          router: 'Invitation',
          i: '0',
          IsOpen: true,
        },
        {
          image: require('@/assets/img/assets_icon.png'),
          image1: require('@/assets/imgsnowball/tibi1.png'),
          name: 'lang.h89',
          router: 'assetsIndex',
          IsOpen: true,
        },
        {
          image: require('@/assets/img/shoyi_icon.png'),
          image1: require('@/assets/imgsnowball/zhuanzhang1.png'),
          name: 'lang.h90',
          router: '',
          i: '2',
          IsOpen: true,
        },
        {
          image: require('@/assets/img/liaojie_icon.png'),
          image1: require('@/assets/imgsnowball/zhangdan1.png'),
          name: 'lang.h91',
          router: '',
          IsOpen: true,
        },
      ],
      page: 1,

      D6price: 0,
      death: 0,
      myBalance: 0,
      chainInviteInfo: '',
      profit: 0,
      swanPrice: 0,
      institutionsListA: [
        require('@/assets/img/icon/icon1.png'),
        require('@/assets/img/icon/icon2.png'),
        require('@/assets/img/icon/icon3.png'),
        require('@/assets/img/icon/icon4.png'),
        require('@/assets/img/icon/icon5.png'),
        require('@/assets/img/icon/icon6.png'),
      ],
      institutionsListB: [
        {
          image: require('@/assets/img/slowmist.png'),
          image1: require('@/assets/img/slowmist1.png'),
        },
        {
          image: require('@/assets/img/certik.png'),
          image1: require('@/assets/img/certik1.png'),
        },
      ],
      institutionsListC: [
        {
          image: require('@/assets/img/binance.png'),
          image1: require('@/assets/img/binance1.png'),
        },
        {
          image: require('@/assets/img/okchain.png'),
          image1: require('@/assets/img/okchain1.png'),
        },
        {
          image: require('@/assets/img/trustwallet.png'),
          image1: require('@/assets/img/trustwallet1.png'),
        },
        {
          image: require('@/assets/img/metamask.png'),
          image1: require('@/assets/img/metamask1.png'),
        },
        {
          image: require('@/assets/img/coinmarketcap.png'),
          image1: require('@/assets/img/coinmarketcap1.png'),
        },
        {
          image: require('@/assets/img/bitkeep.png'),
          image1: require('@/assets/img/bitkeep1.png'),
        },
        {
          image: require('@/assets/img/tokenpocket.png'),
          image1: require('@/assets/img/tokenpocket1.png'),
        },
        {
          image: require('@/assets/img/coingecko.png'),
          image1: require('@/assets/img/coingecko1.png'),
        },
        {
          image: require('@/assets/img/imtoken.png'),
          image1: require('@/assets/img/imtoken1.png'),
        },
        {
          image: require('@/assets/img/openchat.png'),
          image1: require('@/assets/img/openchat1.png'),
        },
      ],
      institutionsListIcon: [require('@/assets/img/IconA.png'), require('@/assets/img/IconB.png'), require('@/assets/img/IconC.png'),require('@/assets/img/IconD.png')],
      allReword: 0,
      site: '',
      mapShow: false, //发展路线图状态

      phoPrice: 0,
      tokenqutoe: [],
      tokenqutoe2: [],
      tokenimgurls: '',
      timer: null,
      RwaInfo:{
        Rwaprice:0,
        production:0,
        publish_amount:0
      },
    };
  },

  created() {
    this.init();

    this.scrollLoad();
    this.scrollLoadmain();
  },
  components:{
    AssetsIndex
  },
  mounted() {
    window.addEventListener('scroll', this.scrollLoad, true);
    window.addEventListener('scroll', this.scrollLoadmain, true);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    window.removeEventListener('scroll', this.scrollLoad, true);
    window.removeEventListener('scroll', this.scrollLoadmain, true);
  },
  watch: {
    account() {
      this.init();
    },
    lang() {
      this.init();
    },
  },

  methods: {
    bNav(router, i = '') {
      if (router != '') {
        if (i != '') {
          this.$router.push({ name: router, query: { id: i } });
        } else {
          this.$router.push({ name: router });
        }
      } else {
        // this.$toast(this.$t('lang.swap65'));
      }
    },
    getbance() {
      homedata.getbancer(this.account).then(res => {
        console.log(res);
        this.imglist = res.data;
      });
    },

    // tokenrwaprice(){
    //   bizhong.tokenrwaprice(this.account).then(res=>{
             
    //      })
    // },
   
    isfirstgongao() {
      if (this.gongaoid != this.notice.group_id) {
        this.$store.commit('SETGONGAOID', this.notice.group_id);
        this.gongaoshow = true;
      } else {
        this.gongaoshow = false;
      }
    },
    gongaox() {
      this.gongaoshow = false;
    },
    getnewnotice() {
      gongao.getNewNotice().then(res => {
        console.log(res);
        this.notice = res.data;
        this.isfirstgongao();
      });
    },
    getqianbao() {
      moneymask.getqianbao(this.account).then(res => {
        this.zhicanlist = res.data;
      });
    },
    getimgurl() {
      homedata.gettokenimgurl().then(res => {
        this.imgurl = res.data.image_domain;
      });
    },

    myBalancefun() {
      let data = {
        token_id: 5,
      };
      moneymask.getwalletgetinfo(data, this.account).then(res => {
        this.myBalance = res.data.usable;
      });
    },
    scrollLoad() {
      // 1.获取所有的box
      var boxList = document.querySelectorAll('.img');
      //2. 定义一个目标值
      var targetValue = window.innerHeight * 0.8;

      //3.获取每一个box距离浏览器顶部的值
      boxList.forEach(function (box) {
        var boxTop = box.getBoundingClientRect().top;

        if (boxTop <= targetValue) {
          box.classList.add('show-center');
        } else {
          box.classList.remove('show-center');
        }
      });
    },

    scrollLoadmain() {
      // 1.获取所有的box
      var boxList = document.querySelectorAll('.universal_box');
      //2. 定义一个目标值
      var targetValue = window.innerHeight * 0.8;

      //3.获取每一个box距离浏览器顶部的值
      boxList.forEach(function (box) {
        var boxTop = box.getBoundingClientRect().top;

        if (boxTop <= targetValue) {
          box.classList.add('show-center');
        } else {
          box.classList.remove('show-center');
        }
      });
    },
    async init() {
      if(this.account){
        Promise.all([ this.getimgurl(),
      this.getbance(),
      this.getnewnotice(),
      this.gettokenquote(),
      this.getRWAprice()
    ])
      }
     
    },
     gettokenquote(){
       bizhong.gettokenquote(this.account).then(res=>{
            if(res.code == 200){
              this.quoteList = res.data
            }
       })
    },
    getRWAprice(){
   web3_api.getCurrentPairPrice(['0x6541dcc04855472b70247642d813f5396e97f276','0xdb0befa68726af2728717222bcb272497db895b8'],'0x91fDA7E9251bbbDF1245687052824c8F3Aab4f51',18,18).then(res=>{
  
   })      
   web3_api.getCurrentPairPrice(['0x6541dcc04855472b70247642d813f5396e97f276'],'0x684033bd95d3bb92affde013c1e3c5266567c2ce',18,18).then(res=>{
    this.RwaInfo.Rwaprice = res
           
   })
    },

    without() {
      this.$router.push({ name: 'Mining' });
    },
    toSwap() {
      this.$router.push({ name: 'Swap' });
    },

    mapclick() {
      console.log(111);
      this.mapShow = !this.mapShow;
    },
  },
  computed: {
    ...mapState(['account', 'lang', 'gongaoid', 'ColorCheckData']),
  },
};
</script>

<style lang="less" scoped>
.assets_box {
  width: 100%;
  border-radius: 16px;

  background: rgba(25, 29, 41, 1);
  .box_title {
    font-size: 16px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  & > div {
    padding: 17px 18px;
  }
}
.routemap2{
  padding: 27px 9px 20px 9px;
  width: 100%;
  background: url('~@/assets/img/routemap2_bg.png') no-repeat;
  background-size: contain;
  height: 449px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  img{
    position: absolute;
    left: -40%;
    bottom: 16px;
    width: 228px;
height: 133px;
  }
  .routemap2_line{
        width: 100%;
       
        text-align: right;
        &:nth-child(2n){
          .routemap2_line_box{
                float: right;
          } 
          text-align: left;
        }
        .routemap2_line_box{
          font-size: 12px;
           float: left;
             .routemap2_line_box_title{
color: rgba(255, 255, 255, 1);

             }
             .routemap2_line_box_text{
              color: rgba(132, 138, 150, 1);
             }
        }
  }
}
.Advantage {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .Advantage_box {
    border-radius: 16px;
    background: rgba(25, 29, 41, 1);
     padding: 11px;  
    border: 1px solid rgba(36, 40, 52, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48%;
    margin-bottom: 16px;
    padding-top: 10px;
    img {
      width: 116px;
      height: 116px;
    }
    .Advantage_box_title{
      font-size: 18px;
      color: #fff;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .Advantage_box_text{
      font-size: 12px;
      line-height: 16.5px;
color: rgba(255, 255, 255, 0.8);
    }
  }
}
.aboutSwap {
  width: 100%;
  position: relative;
  .aboutSwap_img {
    width: 65px;
    height: 83px;
    position: absolute;
    right: 0;
    top: 20%;
  }
  .aboutSwap_box {
    width: 100%;

    padding: 10px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    line-height: 20px;
    &:last-child {
      background: url('~@/assets/img/aboutSwap_box2.png') no-repeat;
      background-size: cover;
      line-height: 23px;
    }
  }
  .aboutSwap_box1 {
    margin-bottom: 20px;
    background: url('~@/assets/img/aboutSwap_box1.png') no-repeat;
    background-size: cover;
  }
}
.rwabox {
  width: 100%;
  padding: 12px;
  border-radius: 16px;
  background: rgba(25, 29, 41, 1);

  border: 1px solid rgba(36, 40, 52, 1);
  .rwabox_line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 700;
    .rwabox_line_left {
      color: rgba(255, 255, 255, 0.6);
    }
    .rwabox_line_right {
      color: #fff;
    }
  }
  .rwabox_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .rwabox_top_item {
      border: 1px solid rgba(36, 40, 52, 1);
      background: rgba(16, 20, 32, 1);
      width: 48%;
      padding: 10px 13px;
      border-radius: 8px;
      .rwabox_top_item_title {
        margin-bottom: 7px;
        font-size: 14px;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.6);
      }
      .rwabox_top_item_num {
        font-size: 20px;
        color: #fff;
        margin-bottom: 8px;
        font-weight: 700;
      }
      .rwabox_top_item_button {
        border-radius: 8px;
        background: rgba(152, 232, 110, 1);
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #000;
        font-weight: 700;
        img {
          width: 20px;
          height: 20px;
          margin-right: 9px;
        }
      }
    }
  }
}
.zhuzao_button {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(20, 20, 20, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 91px;
  height: 36px;
  opacity: 1;
  border-radius: 36px;
  padding: 2px;
  background: rgba(152, 232, 110, 1);
  .down_icon {
    transform: rotate(-90deg);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: var(--bg-color);
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.page_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 27px;
  color: var(--text-color);
  position: relative;
  .page_title_item {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 72px;
      height: 2px;
      opacity: 1;
      background: linear-gradient(121.43deg, rgba(164, 193, 167, 0) 0%, rgba(152, 232, 110, 1) 54.17%, rgba(182, 133, 255, 0) 100%);
    }
  }
}
.home_page {
  position: relative;
  background: var(--bg-color);
  padding: 0 0 20px;
  overflow-x: hidden;
  margin-top: 30px;
  .gradient {
    font-weight: bold;
    background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .explanation {
    position: relative;
    font-size: 14px;
    // font-weight: bold;
    color: #b8b8b8;
    z-index: 1;
    padding: 30px 20px;
    p {
      color: var(--text-color);
      font-size: 28px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      text-align: left;
      line-height: 30px;
    }
    span {
      color: #5883bfff;
      font-size: 12px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
    }
  }
  .route_map {
    position: relative;
    margin: 0 auto;
    width: 334px;
    border: 1px solid rgba(52, 247, 244, 0.49);
    border-radius: 30px;
    opacity: 1;
    background: var(--box-bg-color);
    padding: 20px;
    z-index: 1;
    .map {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: var(--text-color);
      font-weight: bold;
      .routemap_img {
        display: block;
        width: 44px;
        height: 44px;
      }
    }
    .map_list {
      font-size: 14px;
      color: #848a96;
      p {
        color: var(--text-color) !important;
      }
      // font-weight: bold;
      div {
        margin-top: 16px;
      }
    }
    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: linear-gradient(90deg, #1a70fdff 0%, #26c3ffff 100%);
      font-weight: bold;
      margin-top: 30px;
      .more_img {
        display: block;
        width: 12px;
        height: 16px;
        margin-left: 6px;
      }
    }
  }
  .exhibit {
    // background: var( --text-color);
    margin: 10px 0 10px;
    padding: 40px 0;
    .hold {
      font-size: 18px;
      font-weight: bold;
      color: var(--text-color);
      text-align: center;
    }
    .exhibit_li {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: linear-gradient(90deg, #1a70fdff 0%, #26c3ffff 100%);
      margin-top: 20px;

      .li_top {
        font-size: 34px;
        font-weight: bold;
        span {
          background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .li_button {
        font-size: 12px;
        color: var(--text-color);
        margin-top: 6px;
      }
    }
    .specialmain {
      height: 120px;
      overflow: hidden;
      margin-top: 40px;
    }
    .special {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .special_li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 49%;
        position: relative;
        transition: transform 0.5s ease;
        .sli_yuan {
          width: 32px;
          height: 32px;
          position: absolute;
          left: 30px;
          border-radius: 50%;
        }
        .slitext {
          position: absolute;
          right: 40px;
          line-height: 10px;
          .wbnb {
            color: #5883bfff;
            font-size: 12px;
            font-weight: 500;
            font-family: 'PingFang';
          }
        }
      }
    }
  }
  .universal_box {
    overflow: hidden;
    border-radius: 20px;
    background: var(--box-bg-color);
    position: relative;
    padding: 20px;
    margin: 0 auto;
    margin-top: 20px;
    transition: transform 0.5s ease;
    width: 334px;
    .abs_img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 91.73px;
      height: 88.91px;
    }
    // border-radius: 30px;
    .clcaption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #c4c4c4;
      font-weight: bold;
      .pledge_img {
        display: block;
        width: 44px;
        height: 44px;
      }
    }
    .slogan {
      display: flex;
      flex-direction: column;
      font-size: 30px;
      font-weight: bold;
      color: var(--text-color);
      margin: 10px 0;
    }
    .box_button {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 40%;
      border-radius: 16px;
      background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
      font-size: 14px;
      color: var(--text-color);
      padding: 16px 0;
      margin-top: 20px;
      .box_arrow {
        display: block;
        width: 16px;
        height: 10px;
        margin-left: 8px;
      }
    }
  }
  .universal_box:nth-child(even) {
    transform: translateX(-250%);
  }

  /* 奇数 */
  .universal_box:nth-child(odd) {
    transform: translateX(250%);
  }

  .universal_box.show-center {
    transform: translateX(0);
  }
  .merchandise_bg {
    // background: url('~@/assets/img/merchandise_bg.png') no-repeat;
    background-size: 100% 100%;
  }
  .earncoins_bg {
    background: url('~@/assets/img/earncoins_bg.png') no-repeat;
    background-size: 100% 100%;
    .million {
      font-size: 14px;
      font-weight: bold;
      color: var(--text-color);
    }
  }
  .emission_bg {
    background: url('~@/assets/img/emission_bg.png') no-repeat;
    background-size: 100% 100%;
  }
  .market_bg {
    background: url('~@/assets/img/market_bg.png') no-repeat;
    background-size: 100% 100%;
  }
  .super_bg {
    background: url('~@/assets/img/super_bg.png') no-repeat;
    background-size: 100% 100%;
  }

  .partner_list {
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    // border: 1px solid #3399ff;
    padding: 26px 20px 20px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .partner {
      width: 100%;
      margin-bottom: 30px;
      .partner_title {
        font-size: 18px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: var(--text-color);
        text-align: center;
        margin-bottom: 20px;
      }
      .partner_img {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;
        .img {
          width: 45%;
          height: 47px;
          border-radius: 10px;
          margin-top: 14px;
          background: var(--box-bg-color);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: transform 0.5s ease;
        }

        /* 偶数 */
        .img:nth-child(even) {
          transform: translateX(-250%);
        }

        /* 奇数 */
        .img:nth-child(odd) {
          transform: translateX(250%);
        }

        .img.show-center {
          transform: translateX(0);
        }

        img {
          width: 100%;
          height: 110%;
        }
        .partner_li {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48%;
          height: 52px;
          border-radius: 16px;
          padding: 10px;
          background: var(--text-color);
          margin-bottom: 14px;
        }
      }
    }
    .reserved {
      color: var(--text-color);
      font-size: 10px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      text-align: center;
    }
    .Icon_img {
      width: 149.89px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 40px;
      .img {
        width: 24px;
        height: 21.74px;
      }
    }
  }
}
.inDapp {
  margin: 0 auto;
  width: 336px;
  height: 44px;
  margin-bottom: 49px;
  border-radius: 10px;
  opacity: 1;
  background: linear-gradient(90deg, #1a70fdff 0%, #26c3ffff 100%);
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  font-family: 'PingFang';
  text-align: center;
  line-height: 44px;
}
/deep/.van-icon-success {
  color: var(--text-color) !important;
}
.snowballmain {
  padding: 20px;
}

.homeimg {
  width: 100%;
  border-radius: 15px;
}

.routerlink {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .router_li {
    width: 25%;

    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .imgbox {
      display: flex;
      align-items: center;
      justify-content: center;
      .imgbox_item {
        // width: 38px;
        // height: 38px;

        // border-radius: 50%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        img {
          margin-bottom: 6px;
          width: 24px;
          height: 24px;
        }
      }
    }

    span {
      color: var(--text-color);
      font-size: 13px;
      font-weight: 500;
      font-family: 'PingFang';
    }
  }
}

.notice {
  position: relative;
  margin-top: 30px;
  width: 333px;
  height: 60px;
  border-radius: 16px;
  opacity: 1;
  background: var(--box-bg-color);
  border: 1px solid rgba(36, 40, 52, 1);
  padding: 10px;
  display: flex;
  align-items: center;

  img {
    height: 100%;
    margin-right: 10px;
  }

  span {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
  }

  .shoyi {
    position: absolute;
    right: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: url('~@/assets/imgsnowball/shoyiyou.png') no-repeat;
    width: 36px;
    height: 36px;
    background-size: contain;
  }
}

.d6 {
  color: #1d1d1d;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Poppins';
}

.jiasho {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .box {
    padding: 11px 12px;

    border-radius: 10px;
    height: 100%;
    background: #26272bff;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .jiage {
    .j_span1 {
      color: #ced0deff;
      font-size: 12px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .j_span2 {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
    }

    .j_span3 {
      color: #5fc88fff;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Poppins';
    }

    .j_div1 {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .shandui {
        color: var(--text-color);
        width: 41px;
        height: 21px;
        border-radius: 55px;
        opacity: 1;
        background: #808cf5;
        text-align: center;
        line-height: 21px;
        font-size: 10px;
        font-weight: 500;
        font-family: 'HarmonyOSSansSC';
      }
    }
  }

  .d_shoyi {
    position: relative;

    .s_div {
      align-self: flex-end;
      width: 41px;
      height: 21px;
      border-radius: 55px;
      position: absolute;
      bottom: 10px;
      background: #ffce57ff;
      color: #1c203aff;
      font-size: 10px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      text-align: center;
      line-height: 21px;
    }

    .s_span1 {
      color: #ffce57ff;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .s_span2 {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      width: 60%;
      display: flex;
      justify-content: space-between;
      // display: flex;
      // justify-content: space-between;
      // width: 50%;
    }
  }
}

.echathead {
  .e_span1 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
    margin-right: 20px;
  }

  .e_span2 {
    color: #9395a4ff;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';

    img {
      width: 12px;
      height: 12px;
    }
  }
}

#echat {
  margin: 0 auto;
}

.zhuzao {
  width: 100%;

  border-radius: 16px;
  opacity: 1;
  position: relative;
  background: var(--box-bg-color);

  border: 1px solid rgba(52, 247, 244, 0.49);
  padding: 15px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  .zhuzao_left {
    display: flex;
    flex-direction: column;
    .zhuzao_left_title {
      font-size: 16px;
      font-weight: bold;
      & > :first-child {
        color: rgba(55, 249, 252, 1);
      }
      & > :last-child {
        color: var(--text-color);
      }
    }
  }
  .zhuzao_button {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(20, 20, 20, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 16px;
    bottom: 22px;
    width: 91px;
    height: 36px;
    opacity: 1;
    border-radius: 36px;
    padding: 2px;
    background: rgba(152, 232, 110, 1);
    .down_icon {
      transform: rotate(-90deg);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background: var(--bg-color);
      color: var(--text-color);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  img {
    width: 111px;
    height: 67px;
    margin-top: 6px;
    margin-left: 20px;
  }
}

.zichantitle {
  display: flex;
  align-items: center;
  margin-top: 30px;

  .title {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
    margin-right: 40px;
  }

  //    /deep/.van-checkbox__icon--square{
  //     position: absolute;
  //     left: 1px;
  //     top: 5px;

  // }
  .yingcang {
    color: #999999ff;
    font-size: 12px;
    font-weight: 500;
    font-family: 'PingFang';
  }

  /deep/.van-checkbox__icon .van-checkbox__icon {
    border-color: #000 !important;
  }

  /deep/.van-checkbox__icon--checked .van-icon-success {
    color: #8b5411;
  }
}

.zichanlist {
  margin-top: 10px;
  width: 100%;
  // height: 213px;
  border-radius: 10px;
  background: var(--box-bg-color);
  border: 1px solid rgba(52, 247, 244, 0.49);
  padding: 17px 14px;
  padding-top: 0;

  .li_title {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: #999999ff;
    font-size: 12px;
    font-weight: 700;
    font-family: 'PingFang';
    border-bottom: 1px solid rgba(38, 41, 53, 1);
    padding-bottom: 12px;
  }
}

.li_con {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  div {
    color: var(--text-color);
    width: 30%;
    text-align: center;
    line-height: 40px;
  }

  .li1 {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}

.overflow {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .gongaox {
    position: absolute;
    right: 21px;
  }

  .o_cont {
    position: relative;
    width: 335px;
    min-height: 250px;

    overflow: hidden;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px 21px;
    background: var(--line-bg-color);

    .line {
      background: var(--text-color);
      width: 100%;
      height: 1px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .title {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 350px;
      overflow-y: scroll;

      div {
        margin-bottom: 10px;
        white-space: pre-wrap;

        white-space: normal;
        word-break: break-word;
      }
    }
  }
}

.assets {
  padding-bottom: 20px;
  width: 100%;

  .a_title {
    color: #999999;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
  }

  .a_number {
    .num {
      color: #000;
      font-size: 36px;
      font-weight: 500;
      font-family: 'Poppins';
    }

    .tit {
      margin-left: 5px;
      color: #000;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Poppins';
    }
  }

  .a_shoyi {
    display: flex;

    .num {
      color: #c75d5d;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Poppins';
    }

    .tit {
      margin-left: 10px;
      color: #999999;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Poppins';
    }
  }
}
</style>