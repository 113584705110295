<template>
  <div class="importMining">
    <div class="function_top">
      <div @click="liquidity"
           class="function_button">
        <div>
          <img class="left_arrow"
               src="@/assets/img/left_arrow.png" v-if="ColorCheckData == 'black'" />
               <img class="left_arrow"
               src="@/assets/img/left_arrow1.png" v-else />
        </div>
        <div class="liquidity_title">
          <span class="title">{{ $t("lang.swap106") }}</span>
          <div>
            <span>{{ $t("lang.swap107") }}</span>
          </div>
        </div>
      </div>
      <div>
        <div class="install_img" v-if="ColorCheckData == 'black'">
          <img class="install"
               src="@/assets/img/set.png"
               @click="handleSettingShow"/>   
          <img class="refresh"
               src="@/assets/img/record.png"
               @click="handleRecordShow" />
        </div>
        <div class="install_img" v-else>
          <img class="install"
               src="@/assets/img/set1.png"
               @click="handleSettingShow"/>   
          <img class="refresh"
               src="@/assets/img/record1.png"
               @click="handleRecordShow" />
        </div>
      </div>
    </div>

    <div class="content">
      <div class="importMining_input"
           @click="handleGetCurrency('from')">
        <div>
          <img class="logoURI"
               :src="fromCur.logoURI" />
          <span>{{ fromCur.symbol }}</span>
        </div>
        <div>
          <img class="arrows"
               src="@/assets/img/arrows.png" />
        </div>
      </div>

      <img src="@/assets/img/subjoin.png"
           class="iconDown" />

      <div class="importMining_input"
           @click="handleGetCurrency('to')">
        <div>
          <img class="logoURI"
               :src="toCur.logoURI" />
          <span>{{ toCur.symbol }}</span>
        </div>
        <div>
          <img class="arrows"
               src="@/assets/img/arrows.png" />
        </div>
      </div>

      <div class="mobility">
        <div class="nothing"
             v-if="!(fromCur.symbol && toCur.symbol)">
          <span>{{ $t("lang.swap108") }}</span>
        </div>
        <div class="nothing"
             v-else-if="lpDetail.lpBalance == 0">
          <span>{{ $t("lang.swap109") }}</span>
          <van-button type="primary"
                      class="nothing_swapBtn"
                      @click="increase">+{{ $t("lang.swap94") }}</van-button>
        </div>
        <div class="mobility_token"
             v-else>
          <div class="title">
            <span>{{ $t("lang.swap110") }}</span>
          </div>
          <div class="detailed">
            <div class="quantity">
              <image class="img"
                     :src="fromCur.logoURI" />
              <image class="img"
                     :src="toCur.logoURI" />
              <span>{{ fromCur.symbol }}</span>
              /
              <span>{{ toCur.symbol }}</span>
            </div>
            <div>
              <span>{{ significantDigits(lpDetail.lpBalance / Math.pow(10,18)) }}</span>
            </div>
          </div>
          <div class="detailed">
            <div class="quantity">
              <span>{{ $t("lang.swap96") }}：</span>
            </div>
            <div>
              <span>{{ (lpDetail.scale * 100).toFixed(10) }}%</span>
            </div>
          </div>
          <div class="detailed">
            <div class="quantity">
              <span>{{ $t("lang.swap103") }} {{ lpDetail.fromSymbol }}:</span>
            </div>
            <div>
              <span>{{ (lpDetail.fromBalance) }}</span>
            </div>
          </div>
          <div class="detailed">
            <div class="quantity">
              <span>{{ $t("lang.swap103") }} {{ lpDetail.toSymbol }}:</span>
            </div>
            <div>
              <span>{{ significantDigits(lpDetail.toBalance) }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="exchange_button"
           v-if="(fromCur.symbol && toCur.symbol && lpDetail.lpBalance != '0'  )">
        <van-button type="primary"
                    class="swapBtn"
                    @click="selectingToken">Manage this pool</van-button>
      </div>
    </div>
    <van-popup v-model="transactionRecordShow"
               round
               position="bottom">
      <transaction-record @listenClose="handleTransactionRecordClose" />
    </van-popup>
    <van-popup v-model="settingShow"
               round
               position="bottom">
      <SettingsModal @listenClose="handleSettingClose" />
    </van-popup>
    <van-popup v-if="tokenListShow"
               v-model="tokenListShow"
               round
               position="bottom">
      <CurrencyList @listenClose="handleTokenListClose"
                    @listenSelecteCurrency="handlerSelecteCurrency" />
    </van-popup>
  </div>
</template>

<script>
import TransactionRecord from '@/views/swap/TransactionRecord.vue';
import SettingsModal from '@/components/SettingsModal.vue';
import CurrencyList from '@/components/CurrencyList.vue';
import { mapState } from 'vuex';
import { homedata } from '../../api';
import { getErc20Contract, getJackRouterContractV2, getJackPairContract, getJackFactoryContractV2 } from '@/utils/contractHelp';

import { formatAmount, parseAmount, accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, removal, gasProcessing } from '@/utils/format.js';
export default {
  data () {
    return {
      settingShow: false, //设置弹窗状态
      transactionRecordShow: false, //交易弹窗状态
      tokenListShow: false,
      tokenSource: '',
      isV1: true,
      imgurl:'',
      fromCur: {
        name: 'BNB',
        symbol: 'BNB',
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        chainId:56,
        decimals: 18,
        balance: '',
        logoURI: require('@/assets/img/BNB.png'),
      },
      toCur: {
        balance: '',
        name: '',
        symbol: '',
        address: '',
        chainId: '',
        decimals: '',
        logoURI: '',
      },

      lpDetail: {
        lpBalance: 0, //余额
        showLpBalance: 0,
        lpAddress: '',
        scale: '',
        fromAddress: '',
        fromSymbol: '',
        fromDecimals: '',
        fromBalance: '',
        toAddress: '',
        toSymbol: '',
        toBalance: '',
        toDecimals: '',
      },
      storeLp: {
        fromAddress: '',
        fromSymbol: '',
        fromDecimals: '',
        toAddress: '',
        toSymbol: '',
        toSymbol: '',
        lpAddress: '',
      },

      storeLpList: [], //自定义代币
      switchVersionShow: '0',
    };
  },
  async created () {
    homedata.gettokenimgurl().then(res =>{
      this.imgurl =res.data.image_domain

    })
    try {
      this.storeLpList = localStorage.getItem('lpListV2') ? JSON.parse(localStorage.getItem('lpListV2')) : [];
    } catch (e) {
      this.storeLpList = [];
    }
  },
  methods: {
    async selectingToken () {
      this.storeLp.fromAddress = this.lpDetail.fromAddress;
      this.storeLp.toAddress = this.lpDetail.toAddress;
      // this.storeLp.lpAddress = this.lpDetail.lpAddress;
      this.storeLp.fromSymbol = this.lpDetail.fromSymbol;
      this.storeLp.fromDecimals = this.lpDetail.fromDecimals;
      this.storeLp.toSymbol = this.lpDetail.toSymbol;
      this.storeLp.toDecimals = this.lpDetail.toDecimals;
      const jackFactoryContract = getJackFactoryContractV2();
      this.storeLp.lpAddress = await jackFactoryContract.methods.getPair(this.storeLp.fromAddress, this.storeLp.toAddress).call();
      if (this.storeLpList.length == 0) {
        this.storeLpList.push(this.storeLp);
      } else {
        let isHave = false;
        for (var i = 0; i < this.storeLpList.length; i++) {
          if (this.storeLpList[i].lpAddress == this.storeLp.lpAddress) {
            isHave = true;
            break;
          }
        }
        if (!isHave) {
          this.storeLpList.push(this.storeLp);
        }
      }
      localStorage.setItem('lpListV2', JSON.stringify(this.storeLpList));
      this.storeLp = {
        fromAddress: '',
        fromSymbol: '',
        fromDecimals: '',
        toAddress: '',
        toSymbol: '',
        toDecimals: '',
        lpAddress: '',
      };
      this.toCur = {
        balance: '',
        name: '',
        symbol: '',
        address: '',
        chainId: '',
        decimals: '',
        logoURI: '',
      };
      this.$router.go(-1);
    },

    async search () {
      if (this.fromCur.address && this.toCur.address) {
        this.lpDetail.fromAddress = this.fromCur.address;
        this.lpDetail.toAddress = this.toCur.address;
        this.lpDetail.fromSymbol = this.fromCur.symbol;
        this.lpDetail.toSymbol = this.toCur.symbol;
        this.lpDetail.fromDecimals = this.fromCur.decimals;
        this.lpDetail.toDecimals = this.toCur.decimals;
        let jackFactoryContract = getJackFactoryContractV2();
        this.lpDetail.lpAddress = await jackFactoryContract.methods.getPair(this.fromCur.address, this.toCur.address).call();
        if (this.lpDetail.lpAddress == '0x0000000000000000000000000000000000000000') return;
        const jackPairContract = getJackPairContract(this.lpDetail.lpAddress);
        this.lpDetail.lpBalance = await jackPairContract.methods.balanceOf(this.account).call();
        this.lpDetail.showLpBalance = formatAmount(this.lpDetail.lpBalance) * 1;
        let allBalance = await jackPairContract.methods.totalSupply().call();
        this.lpDetail.scale = accDiv(this.lpDetail.lpBalance, allBalance) * 1; //当前比例
        let reserves = await jackPairContract.methods.getReserves().call(); //获取当前总额
        if (this.fromCur.address.toLowerCase() < this.toCur.address.toLocaleLowerCase()) {
          this.lpDetail.fromBalance = (reserves._reserve0 * this.lpDetail.scale) / Math.pow(10, this.lpDetail.fromDecimals);
          this.lpDetail.toBalance = (reserves._reserve1 * this.lpDetail.scale) / Math.pow(10, this.lpDetail.toDecimals);
        } else {
          this.lpDetail.fromBalance = reserves._reserve1 / Math.pow(10, this.lpDetail.fromDecimals);
          this.lpDetail.toBalance = reserves._reserve0 / Math.pow(10, this.lpDetail.toDecimals);
        }
      }
    },
    liquidity (i) {
      // this.$emit('liquidity', i);
      this.$router.go(-1);
    },
    increase () {
      this.$router.push({ name: 'LiquidityV2' });
    },
    //关闭币种选择弹窗
    handleTokenListClose () {
      this.tokenListShow = false;
    },

    //点击选择币种
    handleGetCurrency (tokenSource) {
      this.tokenSource = tokenSource;
      this.tokenListShow = true;
    },

    //币种选择
    async handlerSelecteCurrency (currency) {
      this.tokenListShow = false;
      if (this.tokenSource == 'from') {
        if (currency.address == this.toCur.address && currency.name == this.toCur.name) {
          this.turnAround();
        } else {
          this.fromCur = currency;
        }
      } else {
        if (currency.address == this.fromCur.address && currency.name == this.fromCur.name) {
          this.turnAround();
        } else {
          this.toCur = currency;
        }
      }
      this.search();
    },

    //是否显示设置得余额
    handleSettingShow () {
      this.settingShow = true;
    },
    handleSettingClose () {
      this.settingShow = false;
    },
    //最近交易
    handleRecordShow () {
      this.transactionRecordShow = true;
    },
    handleTransactionRecordClose () {
      this.transactionRecordShow = false;
    },
    significantDigits,
  },
  components: {
    CurrencyList,
    TransactionRecord,
    SettingsModal,
  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'multipath', 'pool','ColorCheckData']),
  },
};
</script>

<style lang="less" scoped>
.importMining {
  
  font-family: PingFangSC-Semibold, PingFang SC;
  background: var( --box-bg-color);
  border-radius: 30px 30px 0 0;
  // box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);

  margin-bottom: 14px;
  overflow: hidden;
  .function_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    color: var( --text-color);
    // box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
    padding: 22px 18px 10px 12px;

    .left_arrow {
      width: 44px;
      height: 44px;
      margin-right: 8px;
      // margin-top: 18px;
    }
    .function_button {
      display: flex;
      font-size: 10px;
      // color: #666666;
      // margin: 6px 0 0 22px;
      .liquidity_title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: var( --text-color)9a;
        .title {
          font-size: 18px;
          font-family: Arial-Bold, Arial;
          font-weight: bold;
          color: var( --text-color);
          margin-bottom: 10px;
        }
      }
    }
    .install_img {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      .install {
        width: 44px;
        height: 44px;
        margin-right: 14px;
      }
      .refresh {
        width: 44px;
        height: 44px;
      }
    }
    .functional_region {
      width: 100%;
      display: flex;
      .region {
        display: flex;
        line-height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #000;
        font-weight: bold;
        border: 1px solid #3399ff;
        border-radius: 34px;
        margin-top: 10px;
        span {
          width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px 10px;
        }
        .special {
          font-size: 16px;
          background: #3399ff;
          box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
          border-radius: 16px;
        }
      }
    }
  }
  .content {
    padding: 20px 24px 20px;
    .importMining_input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 400;
      color: var( --text-color);
      background:  var( --line-bg-color);
      border-radius: 10px;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);

      padding: 12px 14px;
      margin: 14px 0;
      div {
        display: flex;
        align-items: center;
      }
      .logoURI {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        color: #000;
        margin-right: 8px;
      }
      .arrows {
        width: 8px;
        height: 4px;
      }
    }
    .iconDown {
      height: 16px;
      width: 16px;
      display: block;
      margin: 30px auto;
      cursor: pointer;
      // transform: rotate(90deg);
    }
    .mobility {
      background:  var( --line-bg-color);
      border-radius: 10px;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);

      margin-top: 14px;
      .nothing {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: var( --text-color);
        // background:  rgba(69, 131, 255, 1);
        padding: 36px 0 26px;
        .nothing_swapBtn {
          font-size: 18px;
          color: #3399ff !important;
          background:var( --line-bg-color);
          border: 1px solid transparent;
          border-radius: 16px;
          // background-clip: padding-box, border-box;
          // background-origin: padding-box, border-box;
          // background-image: linear-gradient(to right, var( --text-color), var( --text-color)), linear-gradient(to right, #356ef2, #834bca, #f87f17);
          padding: 16px 60px;
          margin-top: 16px;
          span {
            display: block;
            color: var( --text-color);
            // background: linear-gradient(to right, #356ef2, #834bca, #f87f17);
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
          }
        }
      }

      .mobility_token {
        font-weight: 400;
        color: var( --text-color);
        padding: 26px 16px;
        .title {
          font-size: 16px;
          // color: #333333;
        }
        .detailed {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          margin-top: 16px;
          div {
            display: flex;
            align-items: center;
          }
          .img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 6px;
          }
          .quantity {
            color: var( --text-color);
          }
        }
      }
    }
    .exchange_button {
      .swapBtn {
        width: 100%;
        font-size: 16px;
        color: #000 !important;
        border-radius: 87px;
        background: rgba(152, 232, 110, 1);
      
        margin-top: 16px;
      }
    }
  }
}
</style>