<template>
    <div class="nftcenter">


        <div class="nftcenter_sum">
            <div class="flex" style="align-items: start">
                <div class="sumbox">
                    <div class="sumbox_title">待产出</div>
                    <div class="sumbox_num"><span>100</span> <span style="font-size: 12px">$</span></div>
                </div>
                <img src="@/assets/imgsnowball/sumbox_img.png" class="sumbox_img" alt="" />
            </div>
            <div class="flex">
                <div class="flex" style="transform: scale(0.8) translateX(-30px)">
                    <div class="sumbox" style="margin-right: 47px;">
                        <div class="sumbox_title">今日产出</div>
                        <div class="sumbox_num"><span>100</span> <span style="font-size: 12px">STone</span></div>
                    </div>
                    <div class="sumbox">
                        <div class="sumbox_title">累计产出</div>
                        <div class="sumbox_num"><span>2000</span> <span style="font-size: 12px">STone</span></div>
                    </div>
                </div>
                <div class="button" @click="gospecieBill">{{ $t('lang.s104') }}</div>
            </div>
        </div>
        <div class="PaddingBox">
            <div class="Box_title">铸币金额</div>
            <div class="flex-bet" style="margin-bottom: 16px;">
                <div class="box_line">2000</div>
                <div
                    style="width: 10%; text-align: center; font-size: 16px; font-weight: bold; color: rgba(255, 255, 255, 1);">
                    $</div>
            </div>
            <div class="flex-bet" style="margin-bottom: 28px;">
                <div class="box_items" v-for="(item, index) in dataList">{{ item }}</div>
            </div>
            <div class="Box_title">铸币金额</div>
            <div>
                <div class="check_line flex-bet">
                    <span>100 USDT</span>
                    <div class="checkbox">
                        <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="checkdata == '1'">
                        <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
                    </div>
                </div>
                <div class="check_line flex-bet">
                    <span>100 RWA</span>
                    <div class="checkbox">
                        <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="checkdata == '2'">
                        <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
                    </div>
                </div>
            </div>
            <div class="Box_button">铸造</div>
        </div>
        <div class="PaddingBox">
            <div class="Box_title" style="margin-bottom: 27px;">交易激励</div>
            <div class="Box_text">请输入交易哈希</div>
            <div class="Box_line_input">
                <input type="text" placeholder="请输入您的交易哈希">
                <div style="color: rgba(152, 232, 110, 1); padding-left: 18px; border-left: 1px solid rgba(87, 87, 87, 1);">校验</div>
            </div>
            <div class="Box_text">您的交易地址</div>
            <div class="Box_line_input">
                <input type="text" placeholder="您的交易地址">
            </div>
            <div class="Box_text">你将获得</div>
            <div class="Box_line_input">
                 0
            </div>
            <div class="Box_button">确定</div>
        </div>




        <van-popup v-model="payshow" position="bottom" round closeable>
            <div class="paypop">
                <div class="title">
                    {{ $t('lang.s117') }}
                </div>
                <div class="paypop_cont">
                    <div class="paypop_checks">
                        <div class="paypop_checks_box" @click="checkdataFun('0')">
                            <span>100 RUSD</span>
                            <div class="checkbox">
                                <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="checkdata == '0'">
                                <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
                            </div>
                        </div>
                        <div class="paypop_checks_box" @click="checkdataFun('1')">
                            <span>100 USDT</span>
                            <div class="checkbox">
                                <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="checkdata == '1'">
                                <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
                            </div>
                        </div>
                    </div>
                    <div class="banlace">
                        <img src="@/assets/imgsnowball/banlce.png" alt="">
                        <div class="banlace_box">
                            <div
                                style="font-size: 16px; color: rgba(0, 0, 0, 1); font-weight: 700; margin-bottom: 7px;">
                                {{ $t('lang.s118') }}</div>
                            <div style="display: flex;">
                                <div style="width: 80px;">RUSD{{ $t('lang.s119') }}：</div> 100
                            </div>
                            <div style="display: flex;">
                                <div style="width: 80px;">USDT{{ $t('lang.s119') }}：</div> 100
                            </div>
                        </div>
                    </div>
                    <div class="button">{{ $t('lang.s120') }}</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { nft } from '../../api/index';
import { mapState } from 'vuex';
import basicUrl from "@/utils/config.js";
import Web3 from 'web3';
export default {
    data() {
        return {


            loading: true,

            payshow: false,
            checkdata: '0',
            dataList: [500, 1000, 2000, 5000]

        };
    },
    computed: {
        ...mapState(['account', 'lang']),
    },
    watch: {
        account() {

            this.init()
        },
        lang() {
            this.init()
        }
    },
    components: {},
    created() {
        this.init()
    },
    mounted() { },
    methods: {
        init() {


            if (this.account) {

            }

        },


        paypopFun() {
            this.payshow = true
        },
        checkdataFun(type) {
            this.checkdata = type
        },
        gospecieBill(){
             this.$router.push({ name: 'specieBill' });
        }

    },
};
</script>
<style scoped lang='less'>
.paypop {
    width: 100%;

    .title {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        padding: 19px 20px;
        color: rgba(34, 34, 34, 1);
        border-bottom: 1px solid rgba(242, 242, 242, 1);
    }

    .paypop_cont {
        padding: 40px 21px;

        .button {
            margin-top: 23px;
            height: 48px;
            opacity: 1;
            border-radius: 110px;
            background: rgba(152, 232, 110, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 700;
            color: #000;
        }

        .banlace {
            padding-bottom: 7px;
            border-bottom: 1px solid rgba(242, 242, 242, 1);
            display: flex;

            img {
                width: 20px;
                height: 20px;
                margin-right: 12px;
            }

            .banlace_box {
                color: rgba(153, 153, 153, 1);
                font-size: 12px;
            }
        }

        .paypop_checks {
            width: 100%;

            .paypop_checks_box {
                padding: 14px 19px;
                font-size: 16px;
                font-weight: 700;
                color: rgba(0, 0, 0, 1);
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 8px;

                border: 1px solid rgba(230, 230, 230, 1);
                margin-bottom: 14px;

                .checkbox {
                    width: 14px;
                    height: 14px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.nftcenter {
    padding: 18px;
    padding-bottom: 44px;
}

.PaddingBox {
    margin: 21px 0 18px 0;
    border-radius: 10px;
    background: rgba(27, 31, 46, 1);
    padding: 16px 17px;
    padding-bottom: 25px;
    .Box_line_input{
        display: flex;
        align-items: center;
        padding: 14px;
        border-radius: 4px;
background: rgba(44, 49, 66, 1);
font-size: 12px;
font-weight: 700;
color: rgba(255, 255, 255, 1);
input{
    background: none;
    outline: none;
    border: none;
    flex:1;
}
    }
    .Box_text{
        margin-top: 24px;
        margin-bottom: 7px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
    }

    .Box_title {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
    }

    .Box_button {
        margin-top: 20px;
        width: 100%;
        height: 46px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(152, 232, 110, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: rgba(0, 0, 0, 1);
        font-weight: bold;
    }

    .check_line {
        padding: 13px 14px;
        border-radius: 4px;
        background: rgba(44, 49, 66, 1);
        width: 100%;
        font-size: 13px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 14px;

        .checkbox {
            width: 16px;
            height: 16px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .flex-bet {

        display: flex;
        align-items: center;
        justify-content: space-between;

        .box_line {
            font-size: 13px;
            color: rgba(255, 255, 255, 1);
            width: 90%;
            padding: 13px 16px;
            border-radius: 4px;
            background: rgba(44, 49, 66, 1);
        }

        .box_items {
            padding: 7px 20px;
            border-radius: 4px;
            background: rgba(44, 49, 66, 1);
            font-size: 13px;
            color: rgba(255, 255, 255, 1);
            font-weight: 600;
        }
    }
}



.nftcenter_sum {
    padding: 13px 17px;
    width: 100%;

    border-radius: 8px;
    background: linear-gradient(136.87deg, rgba(9, 133, 131, 1) 0%, rgba(117, 207, 95, 1) 100%);

    .button {
        width: 53px;
        height: 30px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: rgba(102, 61, 184, 1);
    }

    .sumbox_img {
        width: 65px;
        height: 80px;
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .sumbox {
        font-size: 12px;

        .sumbox_title {
            color: rgba(255, 255, 255, 0.6);
            margin-bottom: 4px;
        }

        .sumbox_num {
            font-size: 20px;
            color: #fff;
        }
    }
}
</style>