//代币列表
export const BNBlist = [
  {
      "chainId": 56,
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "name": "USDT",
      "symbol": "USDT",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/USDT.png')
    },
    {
      "chainId": 56,
      "address": "0xbaE40502Bf58B12b4D262efD388795A842B9Db4B",
      "name": "RUSD",
      "symbol": "RUSD",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/RUSD.png')
    },
    {
      "chainId": 56,
      "address": "0x684033bd95d3bb92affde013c1e3c5266567c2ce",
      "name": "RT",
      "symbol": "RT",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/RT.png')
    },
    {
      "chainId": 56,
      "address": "0x91fDA7E9251bbbDF1245687052824c8F3Aab4f51",
      "name": "RWA",
      "symbol": "RWA",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/RWA.png')
    },
]



