import baseUrl from './config';
export const viewHash = hash => {
  window.open('https://bscscan.com/tx/' + hash);
};

export const viewToken = address => {
  window.open('https://bscscan.com/token/' + address);
};

export  const getFullImagePath = (imagePath) => {
  const apiPrefix = baseUrl.http_url; // 替换为你的接口前缀
  // 判断路径是否以 'http' 或 'https' 开头
  if (imagePath.startsWith('http://') || imagePath.startsWith('https://')) {
      return imagePath; // 如果是完整路径，则直接返回
  }

  // 如果不是完整路径，拼接接口前缀并返回
  return apiPrefix + imagePath;
}


export const  isPC =() => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 如果 userAgent 中不包含常见的移动端标识，则判断为 PC 端
  return !/android|iphone|ipad|ipod|windows phone/i.test(userAgent) || window.innerWidth > 400;
}

export const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 检查是否为移动端设备标识或PC端宽度小于400px
  return /android|iphone|ipad|ipod|windows phone/i.test(userAgent) || window.innerWidth <= 400;
}